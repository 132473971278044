// import config from '@config';
import { trackEngagement } from '@utils/engagement/engagementUtils';
import Amplitude from 'amplitude-js';

const amplitude = Amplitude.getInstance();

// function consoleLogger(eventName, data) {
//   console.log(`Event Name: ${eventName}`);
//   console.table(Object.entries(data));
// }

const AmplitudeHelper = {
  init: () => {
    ///  amplitude.init(config.AMPLITUDE_API_KEY);
  },
  logEvent: (eventName, data) => {
    // try {

    // if (enableLog) consoleLogger(eventName, data);

    //  amplitude.logEvent(eventName, data);
    trackEngagement(data, eventName);
    // } catch (error) {}
  },
  setUserId: () => {
    // userID, employerID
    // try {
    // const sendingUserId = userID;
    // amplitude.setUserId(sendingUserId.toString());
    // amplitude.setUserProperties({
    //   environment: config.ENVIRONMENT,
    //   employerId: employerID,
    // });
    // } catch (error) {}
  },
  setLocation: async () => {
    // lat, lng
    // amplitude.setUserProperties({
    //   Latitude: lat,
    //   Longitude: lng,
    // });
  },
  setUserProperties: () => {
    // data
    //  amplitude.setUserProperties(data);
  },
  // setCustomAttributes: data => {},
  clearUserProperties: () => {
    //  amplitude.setUserId('');
    // amplitude.regenerateDeviceId();
    amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
